::v-deep .el-table th {
  padding: 0px ;
}
::v-deep .el-table thead {
  height: 15px !important;
  color: #474646;
  font-size: 12px;
}
::v-deep .el-table td {
  padding: 3px ;
  font-size: 11px;
}
.imgSize {
  width: 280px;
  height: 260px;
}
.imgSize:hover {
  transform: scale(1.3);
}
.knowSize {
  width: 276px;
  height: 340px;
}
.knowSize:hover {
  transform: scale(1.3);
}
.img-div {
  text-overflow: ellipsis;
  /*对超出容器的部分强制截取，高度不确定则换行*/
  overflow: hidden;
  /*显示省略符号来代表被修剪的文本。*/
  white-space: nowrap;
  line-height: 40px;
  background: linear-gradient(35deg, #CCFFFF, #afacac);
  text-align: center;
  font-size: 14px;
  font-weight: 600;
}
.know-div {
  text-overflow: ellipsis;
  /*对超出容器的部分强制截取，高度不确定则换行*/
  overflow: hidden;
  /*显示省略符号来代表被修剪的文本。*/
  white-space: nowrap;
  height: 40px;
  line-height: 40px;
  background: #409eff;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  color: white;
}
.top {
  padding: 10px;
  background: rgba(0, 153, 229);
  color: #fff;
  text-align: center;
  font-size: 10px;
  border-radius: 2px;
}
.video-small {
  width: 1260px;
  height: 600px;
  object-fit: fill;
  overflow: hidden;
  margin-top: 10px;
  border-radius: 5px;
}
.item-width {
  width: 48%;
}
.item-width2 {
  width: 48%;
  background: #f5cdd7 !important;
  font-weight: 700;
  color: white;
}
