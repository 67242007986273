












































































































































































































































































































.el-upload-tip {
    display: inline;
    font-size: 12px;
  }

